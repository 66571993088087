import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'reactstrap'
import { getFooterNavigation } from '../../../api/navigation'
import { FooterBox, FooterContainer, FooterNav, FooterNavItem, FooterNavLink, FooterSectionHeadline, FooterText } from '../../../styles/footer'

function Footer(props) {

    const [navigation, setNavigation] = useState([])
    const [loadingNav, setLoadingNav] = useState(true)

    useEffect(() => {

        getFooterNavigation()
        .then((res) => {
            setNavigation(res.data.data)
            setLoadingNav(false)
        })
    }, [])

    return (
        <FooterContainer>
            <Container>
                <Row>
                    <FooterBox sm={3}>
                        <FooterSectionHeadline>Knihio</FooterSectionHeadline>
                        <FooterText>
                            Knihio - vyhledávač knih s více než 12.000 tituly v nabídce. Najděte rychle a pohodlně svou příští oblíbenou knihu za nejlepší cenu.
                        </FooterText>
                    </FooterBox>
                    <FooterBox sm={9}>
                        <FooterSectionHeadline>Oblíbené kategorie</FooterSectionHeadline>
                        <FooterNav>
                            {navigation.map((item) => {
                                return (
                                    <FooterNavItem key={item.alias}>
                                        <FooterNavLink to={`/kategorie/${item.alias}`}>{item.name}</FooterNavLink>
                                    </FooterNavItem>
                                )
                            })}
                        </FooterNav>
                    </FooterBox>
                </Row>
            </Container>
        </FooterContainer>
    )
}

export default Footer