import styled from 'styled-components'
import { createGlobalStyle } from 'styled-components'
import { bodyBg, theme } from './vars'

export const GlobalStyle = createGlobalStyle`
    body {
        background: ${bodyBg};
        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
    }

    h1, .h1,
    h2, .h2,
    h3, .h3,
    h4, .h4,
    h5, .h5,
    h6, .h6,
    p, .p {
        font-family: 'Montserrat', sans-serif;
    }

    p {
        line-height: 2;
    }

    @media (min-width: 1200px) {
        .container, .container-lg, .container-md, .container-sm, .container-xl {
            max-width: 1400px;
        }
    }

    body a {
        text-decoration: none !important;
        transition: all .3s ease-out;
    }

    .ui.primary.button, .ui.primary.buttons .button {
        background-color: #C7D900;
    }

    .ui.primary.button:hover, .ui.primary.buttons .button:hover {
        background-color: #333;
    }

    .ui.primary.button, .ui.primary.buttons .button {
        font-size: 14px;
    }

    @media (min-width: 768px) {
        .navbar-expand-md .navbar-nav .dropdown-menu {
            position: absolute;
            column-count: 1;
        }
    }

    .ui.form input:not([type]), .ui.form input[type=date], .ui.form input[type=datetime-local], .ui.form input[type=email], .ui.form input[type=file], .ui.form input[type=number], .ui.form input[type=password], .ui.form input[type=search], .ui.form input[type=tel], .ui.form input[type=text], .ui.form input[type=time], .ui.form input[type=url] {
        background: #f6f6f6;
        border: 0;
    }

    .header_search_bar > .icon.input > i.icon {
        font-size: 1.5rem;
    }

    .header_search_bar > .icon.input > input {
        padding-left: 60px !important;
    }

    .swiper-container {
        z-index: 0;
    }

    .navbar-expand-md .navbar-nav .dropdown-menu {
        box-shadow: 0 0 4px 0 rgba(8, 11, 14, 0.06), 0 6px 6px -1px rgba(8, 11, 14, 0.1);
        border: 0 !important;
        border-radius: 6px;
        padding: 1rem;
    }

    .dropdown-item {
        padding: .5rem 1.5rem;
    }

    .header_search_bar .prompt.label {
        white-space: normal;
        background: #fff!important;
        border: 1px solid #e0b4b4!important;
        color: #9f3a38!important;
        position: absolute;
        top: -40px;
        transition: all .3s ease-out;
    }

    .ui.rating .active.icon,
    .ui.rating .selected.icon,
    .ui.rating .active.selected.icon {
        color: #EDB12D;
    }

    i.icon {
        margin: 0;
    }

    a {
        color: ${theme.primary};
    }

    a:hover {
        color: ${theme.title};
    }

    .swiper-pagination-bullet-active {
        background: ${theme.primary};
    }

`

export const Toasts = styled.div`
    width: 300px;
    position: fixed;
    top: 2rem;
    right: 3rem;
`

export const Toast = styled.div`
    width: 100%;
    ${props => props.type === 'success' && 'background: #2dce89;'}
    ${props => props.type === 'error' && 'background: #f5365c;'}
    margin-bottom: .5rem;
    padding: 1rem;
    border-radius: calc(.375rem - 1px);
    box-shadow: 0 0 2rem 0 rgba(136,152,170,.55);
    transition: all 0.3s ease-out;

    color: #fff;
    font-weight: 700;
    text-align: left;
    margin: 0;
`

export const PageTitle = styled.h1`
    margin-bottom: 0;
    font-weight: 700;
`