import styled from 'styled-components'
import { theme } from './vars'

export const PageSection = styled.div`
    width: 100%;
    padding: 60px 0;
    background-color: ${props => props.bg};
`

export const SectionHeading = styled.h2`
    font-size: 1.5rem;
    margin-bottom: 50px;
    font-weight: 700;
    position: relative;

    ::after {
        content: '';
        height: 2px;
        width: 80px;
        position: absolute;
        left: 0;
        bottom: -10px;
        background: ${theme.primary};
    }
`

export const PageTitle = styled.h1`
    font-size: 3rem;
    font-weight: 700;
`

export const SearchQuery = styled.span`
    color: ${theme.primary};
    text-decoration: underline;
`

export const LastSearchesRow = styled.div`
    margin-top: 30px;
    width: 100%;
`