import React, { useState, useEffect } from 'react'
import { Button, Icon } from 'semantic-ui-react'
import { NavModalCloseWrapper, NavModalContent, NavModalWrapper } from '../../styles/mobileNavigation'
import LastSearchesModule from '../containers/search-modules/modules/LastSearchesModule'
import SearchForm from '../forms/SearchForm'

function MobileSearchModal({ hide }) {

    return (
        <NavModalWrapper>
            <NavModalCloseWrapper>
                <Button icon onClick={() => hide()}><Icon name='close' /></Button>
            </NavModalCloseWrapper>
            <NavModalContent>
                <SearchForm />
                <LastSearchesModule bg='light' />
            </NavModalContent>
        </NavModalWrapper>
    )
}

export default MobileSearchModal