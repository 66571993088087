import React from 'react'
import CategoryPickerItem from './elems/CategoryPickerItem'

function CategoryPicker({ items }) {

    return (
        <>
            {items.map((item) => {
                return (
                    <CategoryPickerItem item={item} />
                )
            })}
        </>
    )
}

export default CategoryPicker