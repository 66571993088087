import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { Icon, Button } from 'semantic-ui-react';
import { getNavigation } from '../../../api/navigation';
import { logo } from '../../../config/images';
import { MobileNavWrapper, Logo } from '../../../styles/mobileNavigation'
import MobileMenuModal from '../../modals/MobileMenuModal';
import MobileSearchModal from '../../modals/MobileSearchModal';

function MobileNavigationContainer() {

    const [isOpen, setIsOpen] = useState(false);
    const [navigation, setNavigation] = useState([])
    const [dropdown, setDropdown] = useState([])
    const [loadingNav, setLoadingNav] = useState(true)
    const [scrolled, setScrolled] = React.useState(false);
    const [searchModal, setSearchModal] = useState(false)
    const [menuModal, setMenuModal] = useState(false)

    const handleScroll = () => {
        const offset = window.scrollY;
        if(offset > 20 ){
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    }

    const toggle = () => setIsOpen(!isOpen);

    useEffect(() => {

        window.addEventListener('scroll', handleScroll)

        getNavigation()
        .then((res) => {
            setNavigation(res.data.data.nav)
            setDropdown(res.data.data.dropdown)
            setLoadingNav(false)
        })
    }, [])

    const toggleSearchModal = () => setSearchModal(!searchModal)
    const toggleMenuModal = () => setMenuModal(!menuModal)

    return (
        <>
        <MobileNavWrapper className={scrolled ? 'scrolled' : 'not-scrolled'}>
            <Row>
                <Col xs={4}>
                    <Link to='/'>
                        <Logo src={logo.color} />
                    </Link>
                </Col>
                <Col xs={4} className='offset-4 p-0 text-right'>
                    <Button icon basic onClick={() => toggleSearchModal()}><Icon name='search' /></Button>
                    <Button icon basic onClick={() => toggleMenuModal()}><Icon name='bars' /></Button>
                </Col>
            </Row>
        </MobileNavWrapper>
        {searchModal &&
        <MobileSearchModal hide={toggleSearchModal} />
        }
        {menuModal &&
        <MobileMenuModal hide={toggleMenuModal} />
        }
        </>
    )
}

export default MobileNavigationContainer