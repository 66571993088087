import React from 'react'
import { Button, Icon } from 'semantic-ui-react'
import { NavModalCloseWrapper, NavModalContent, NavModalWrapper } from '../../styles/mobileNavigation'
import FiltersModule from '../containers/category-modules/FiltersModule'

function MobileFiltersModal({ hide, props, alias, visible }) {

    return (
        <NavModalWrapper style={!visible ? { display: 'none'} : { display: 'block' }}>
            <NavModalCloseWrapper>
                <Button icon onClick={() => hide()}><Icon name='close' /></Button>
            </NavModalCloseWrapper>
            <NavModalContent>
                <FiltersModule type='genre' searchable={true} limit={5} name='zanr' props={props} alias={alias} />
                <FiltersModule type='price' searchable={false} limit={3} name='cena' props={props} alias={alias} />
                <FiltersModule type='publisher' searchable={true} limit={5} name='nakladatelstvi' props={props} alias={alias} />
            </NavModalContent>
        </NavModalWrapper>
    )
}

export default MobileFiltersModal