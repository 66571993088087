import { Link, NavLink } from 'react-router-dom'
import { Button } from 'semantic-ui-react'
import styled from 'styled-components'
import { background } from '../config/images'
import { rad2, shadow1, theme } from './vars'

export const ProductCardContainer = styled.div`
    width: 100%;
    margin-bottom: 60px;
`

export const ProductCardContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 130px;
    justify-content: space-between;
`

export const ProductName = styled.h2`
    font-size: 1.2rem;
    font-weight: 700;
    margin-bottom: 10px !important;

    a {
        color: ${theme.title};
    }

    a:hover {
        color: ${theme.primary};
    }
`

export const ProductNameContainer = styled.div`
    width: 100%;
    flex-shrink: 0;
`

export const ProductImage = styled.img`
    max-width: 85%;
    max-height: 85%;
    box-shadow: ${shadow1};
    display: block;
    border-radius: ${rad2};
`

export const ProductImageContainer = styled.figure`
    height: 300px;
    width: 100%;
    background: ${theme.bg_secondary};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: ${rad2};
    overflow: hidden;

    @media (max-width: 680px) {
        height: 180px;
    }
`

export const Price = styled.p`
    font-weight: 700;
    font-size: 1.5rem;
`

export const PriceFrom = styled.span`
    font-size: 70%;
`

export const StoreLink = styled.a`
    padding: 10px 20px;
    border: 1px solid ${theme.primary};
    display: inline-block;
    color: ${theme.primary};
    border-radius: ${rad2};

    :hover {
        color: #fff;
        background: ${theme.title};
        border: 1px solid ${theme.title};
    }

    @media (max-width: 576px) {
        margin-top: 10px;
        display: block;
        text-align: center;
    }
`

export const LinksContainer = styled.div`
    width: 100%;
    flex-shrink: 0;
    margin-top: 20px;
`

export const CategoryLink = styled(Link)`
    color: ${theme.primary};
    font-weight: 400;
    font-size: 14px;
`

export const AuthorLink = styled.p`
    font-weight: 400;
    font-size: 14px;
`

export const CategoryLinkContainer = styled.div`
    width: 100%;
    flex-shrink: 0;
`

export const HomeHeaderContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    height: 60vh;
    min-height: 650px;
    position: relative;
    overflow: hidden;

    .row {
        width: 100%;
    }

    .ui.form input[type=text] {
        padding: 20px;
        border: 1px solid #f6f6f6;
        transition: all .3s ease-out;
    }

    :before {
        background-image: linear-gradient(45deg, #0F8EA1 38%, #2FA3A1 100%);
        content: '';
        position: absolute;
        bottom: 0;
        left: -24%;
        right: -24%;
        border-radius: 0 0 50% 50%;
        display: block;
        height: 220%;
        z-index: 0;
    }
`

export const HomeHeaderHeadline = styled.h1`
    font-weight: 700;
    color: #fff;
    text-align: center;
    margin-bottom: 40px;
    text-transform: uppercase;
    font-size: 4rem;
`

export const HomeSubHeadline = styled.p`
    text-align: center;
    color: rgba(255, 255, 255, .7);
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
`

export const SearchButton = styled(Button)`
    padding: 22px !important;
`

export const BubbleLink = styled(Link)`
    padding: 15px 40px;
    ${props => props.size === 'small' && 'padding: 10px 40px; font-size: 12px;'}
    border-radius: 500rem;
    margin-right: 5px;
    ${props => props.bg === 'light' && 'border: 1px solid ' +theme.primary +';'}
    ${props => props.bg === 'dark' && 'border: 1px solid #fff;'}
    ${props => props.bg === 'light' && 'color: ' +theme.primary +';'}
    ${props => props.bg === 'dark' && 'color: #fff;'}
    font-weight: 700;

    :hover {
        ${props => props.bg === 'light' && 'color: #fff;'}
        ${props => props.bg === 'light' && 'background: ' +theme.primary +';'}
        ${props => props.bg === 'dark' && 'color: ' +theme.primary +';'}
        ${props => props.bg === 'dark' && 'background: #fff;'}
    }

    @media (max-width: 680px) {
        display: block;
        margin-bottom: 5px;
        text-align: center;
    }
`

export const MobileHeader = styled.div`
    width: 100%;
    background: ${theme.primary};
    padding: 1.5rem;

    .ui.button {
        display: none;
    }

    .ui.form input[type=text] {
        padding: 15px;
        border: 1px solid #f6f6f6;
        transition: all .3s ease-out;
    }
`

export const MainIllustration = styled.img`
    max-width: 600px;
    margin: 40px auto;
    display: block;
`