import React from 'react';
import NavigationContainer from './components/containers/Navigation/NavigationContainer';
import PublicRoutes from './routes/PublicRoutes';
import { isMobileOnly } from 'react-device-detect';
import MobileNavigationContainer from './components/containers/Navigation/MobileNavigationContainer';
import ReactGA from 'react-ga'
import Footer from './components/containers/Footer/Footer';

function App(props) {

  ReactGA.initialize('UA-3501657-7', { debug: false, });

  return (
    <>
    {isMobileOnly ?
    <MobileNavigationContainer props={props} />
    :
    <NavigationContainer props={props} />
    }
    <PublicRoutes />
    <Footer />
    </>
  );
}

export default App;
