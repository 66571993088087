import { Row } from 'reactstrap'
import styled from 'styled-components'
import { theme, defaultBorder, rad1, rad2 } from './vars'

export const PricesListContainer = styled.div`
    width: 100%;
    background: ${theme.bg_secondary};
    padding: 1.5rem;
    margin-bottom: 40px;
    border-radius: ${rad2};
`

export const PriceListItem = styled(Row)`
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: ${defaultBorder};

    :last-child {
        margin-bottom: 0px;
        padding-bottom: 0px;
        border-bottom: 0;
    }

    .ui.placeholder,
    .ui.placeholder .line {
        background-color: transparent;
    }
`

export const SourceLogo = styled.img`
    max-width: 100%;

    @media (max-width: 576px) {
        margin-bottom: 20px;
    }
`

export const SourceDescriptionContainer = styled.div`
    position: relative;
    transition: all .3s ease-out;
    ${props => props.collapse && 'overflow: hidden; height: 45px; ::after { content: ""; position: absolute; bottom: 0; left: 0; right: 0; height: 45px; background: linear-gradient(180deg, rgba(255,255,255,0) 0%, '+ theme.bg_secondary +' 100%); }'}
    ${props => !props.collapse && 'height: 100%; overflow: hidden;'}

    :hover {
        cursor: pointer;
    }
`