import axios from 'axios';
import { api_url, token } from '../config/api';

export function setRating(data) {
    return axios({
        method: 'post',
        url: api_url + 'public/rating/set-rating',
        data: data,
        headers: { Token: token },
        withCredentials: true
    })
}

export function getRating(id) {
    return axios({
        method: 'get',
        url: api_url + 'public/rating/get-rating/' + id,
        headers: { Token: token },
        withCredentials: true
    })
}