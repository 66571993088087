import React, { useState, useEffect } from 'react'
import queryString from 'query-string'
import { searchCategories } from '../../../../api/search';
import CategoryProducts from '../../Category/CategoryProducts';
import { Col, Row } from 'reactstrap';
import { Button } from 'semantic-ui-react';
import LoadingCategoryProducts from '../../Category/LoadingCategoryProducts';
import CategoryPicker from '../../category-modules/CategoryPicker';

function CategoriesResultsModule({props}) {

    const parsed = queryString.parse(props.location.search);
    const [defaultLimit, setDefault] = useState(16);
    const [limit, setLimit] = useState(16);
    const [offset, setOffset] = useState(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getResults()
    }, [props, limit, offset])

    function showMore() {
        setLimit(limit + defaultLimit)
        setOffset(((limit / defaultLimit) - 1) * defaultLimit)
    }

    function getResults() {

        const query = {
            name: parsed.q ? parsed.q : null,
            id_category: parsed.cat ? parsed.cat : null,
            limit: limit,
            offset: offset
        }

        const req = queryString.stringify(query, {
            skipNull: true
        });

        searchCategories(req)
        .then((res) => {
            setData(res.data.data)
            setLoading(false)
        })
    }

    return (
        <>
            {!loading ?
            <CategoryPicker items={data} />
            :
            <LoadingCategoryProducts />
            }
        </>
    )
}

export default CategoriesResultsModule