import React from 'react'
import { BubbleLink } from '../../../../styles/elems'

function CategoryPickerItem({ item }) {

    return (
        <BubbleLink bg='light' to={`/kategorie/${item.alias}`}>
            {item.title}
        </BubbleLink>
    )
}

export default CategoryPickerItem