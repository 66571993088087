import React, { useState, useEffect } from 'react'
import { Col, Row } from 'reactstrap'
import { Placeholder } from 'semantic-ui-react'
import { getPricesList } from '../../../api/products'
import { sources_img_url } from '../../../config/api'
import { Price } from '../../../styles/elems'
import { PriceListItem, PricesListContainer, SourceLogo } from '../../../styles/pricesList'
import { BuyButton } from '../../../styles/product'
import SourceDescription from './elems/SourceDescription'

function PricesList({ ean }) {

    const [prices, setPrices] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getData()
    }, [ean])

    function getData() {
        getPricesList(ean)
        .then((res) => {
            if(res.data.type === 'success') {
                setPrices(res.data.data)
            }
            setLoading(false)
        })
    }

    function PriceItem({ item }) {
        return (
            <PriceListItem>
                <Col sm={3}>
                    <SourceLogo src={sources_img_url + item.source_logo} alt={item.source_name} />
                </Col>
                <Col sm={5}>
                    <SourceDescription text={item.source_description} />
                </Col>
                <Col sm={2} xs={6} className='mt-4 mt-sm-0'>
                    <Price>{item.price_vat},- Kč</Price>
                </Col>
                <Col sm={2} xs={6} className='mt-4 mt-sm-0'>
                    <BuyButton href={`/koupit/${item.feed_id}`} rel="noopener">Koupit</BuyButton>
                </Col>
            </PriceListItem>
        )
    }

    function LoadingData() {
        return (
            <>
            <PriceListItem>
                <Col sm={3}>
                    <Placeholder style={{width: 100 + '%', height: 40}}>
                        <Placeholder.Image square />
                    </Placeholder>
                </Col>
                <Col sm={5}>
                    <Placeholder>
                        <Placeholder.Line length='full' />
                    </Placeholder>
                </Col>
                <Col sm={2}>
                    <Placeholder>
                        <Placeholder.Line length='full' />
                    </Placeholder>
                </Col>
                <Col sm={2}>
                    <Placeholder>
                        <Placeholder.Line length='full' style={{height: 40}} />
                    </Placeholder>
                </Col>
            </PriceListItem>
            <PriceListItem>
                <Col sm={3}>
                    <Placeholder style={{width: 100 + '%', height: 40}}>
                        <Placeholder.Image square />
                    </Placeholder>
                </Col>
                <Col sm={5}>
                    <Placeholder>
                        <Placeholder.Line length='full' />
                    </Placeholder>
                </Col>
                <Col sm={2}>
                    <Placeholder>
                        <Placeholder.Line length='full' />
                    </Placeholder>
                </Col>
                <Col sm={2}>
                    <Placeholder>
                        <Placeholder.Line length='full' style={{height: 40}} />
                    </Placeholder>
                </Col>
            </PriceListItem>
            </>
        )
    }

    function DisplayContent() {
        return prices.map((item) => {
            return (
                <PriceItem item={item} key={item.id_product} />
            )
        })
    }

    return (
        <PricesListContainer>
            {loading ? <LoadingData /> : <DisplayContent />}
        </PricesListContainer>
    )
}

export default PricesList