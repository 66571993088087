import React, { useState, useEffect } from 'react'
import { getCategoryById, getGenreItems, getMostViewed, getNewest } from '../../../api/products'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import ProductCard from './elems/ProductCard';

SwiperCore.use([Pagination, Autoplay]);

function MostViewedContainer({type, id, autoplay = false}) {

    const [products, setProducts] = useState([])
    const [loadingProducts, setLoadingProducts] = useState(true)

    useEffect(() => {
        if(type === 'most-viewed') {
            getMostViewed()
            .then((res) => {
                if(res.data.type === 'success') {
                    setProducts(res.data.data)
                }
                setLoadingProducts(false)
            })
            .catch((err) => {
                setLoadingProducts(false)
                console.log(err)
            })
        } else if(type === 'new') {
            getNewest()
            .then((res) => {
                if(res.data.type === 'success') {
                    setProducts(res.data.data)
                }
                setLoadingProducts(false)
            })
            .catch((err) => {
                setLoadingProducts(false)
                console.log(err)
            })
        } else if(type === 'category-id') {
            getCategoryById(id)
            .then((res) => {
                if(res.data.type === 'success') {
                    setProducts(res.data.data)
                }
                {id !== undefined &&
                setLoadingProducts(false)
                }
            })
            .catch((err) => {
                setLoadingProducts(false)
                console.log(err)
            })
        } else if(type === 'genre-items') {
            getGenreItems(id)
            .then((res) => {
                if(res.data.type === 'success') {
                    setProducts(res.data.data)
                }
                {id !== undefined &&
                setLoadingProducts(false)
                }
            })
            .catch((err) => {
                setLoadingProducts(false)
                console.log(err)
            })
        }
        
    }, [type, id])

    return (
        <>
        {!loadingProducts &&
        <Swiper
            spaceBetween={15}
            slidesPerView={2}
            pagination={{ clickable: true }}
            autoplay={autoplay}
            breakpoints={{
                // when window width is >= 768px
                768: {
                  slidesPerView: 2,
                  spaceBetween: 30
                },
                1024: {
                    slidesPerView: 5,
                    spaceBetween: 50
                }
            }}
        >
            {!loadingProducts && products.map((item) => {
                return (
                    <SwiperSlide key={item.id_product}>
                        <ProductCard item={item} />
                    </SwiperSlide>
                )
            })
            }
        </Swiper>
        }
        </>
    )
}

export default MostViewedContainer