import axios from 'axios';
import { api_url, token } from '../config/api';

export function getFilterData(alias, type) {
    return axios({
        method: 'get',
        url: api_url + 'public/filters/get/'+ alias +'/' + type,
        headers: { Token: token },
        withCredentials: true
    })
}