import React, { useEffect, useState } from 'react'
import { Rating } from 'semantic-ui-react'
import { getRating, setRating } from '../../../api/rating'
import { jsonToFormData } from '../../../tools'

function RatingStarsForm({ id_product }) {

    const [rating, setProductRating] = useState(0)

    useEffect(() => {
        _getRating()
    }, [id_product])

    function _getRating() {
        if(id_product) {
            getRating(id_product)
            .then((res) => {
                setProductRating(res.data.data)
            })
            .catch((err) => {
                console.log('rating error', err)
            })
        }
        
    }

    function _setRating(e, { rating, maxRating }) {
        const data = {
            id_product: id_product,
            rating_value: rating
        }

        const formData = jsonToFormData(data)

        setRating(formData)
        .then((res) => {
            _getRating()
        })
    }

    return (
        <Rating maxRating={5} onRate={_setRating} rating={rating} size='huge' />
    )
}

export default RatingStarsForm