import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'reactstrap'
import { getItem, setHit } from '../../../api/products'
import { AuthorName, ProductActionsContainer, ProductSideInfoContainer, BuyButton, ProductDescription, ProductDescriptionContainer, ProductImage, ProductInfoContainer, ProductInfoRow, ProductMiddleContainer, ProductName, ProductPrice, ShowMoreLink, InfoLabel, InfoValue, GenreName } from '../../../styles/product'
import { PageSection, SectionHeading } from '../../../styles/sections'
import { theme } from '../../../styles/vars'
import MostViewedContainer from '../../containers/product-modules/MostViewedContainer'
import PricesList from '../../containers/product-modules/PricesList'
import RatingStarsForm from '../../forms/Rating/RatingStarsForm'
import ReactGA from 'react-ga'

function ProductView(props) {

    const { id } = props.match.params

    const [product, setProduct] = useState({})
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        window.scrollTo(0, 0)
        getData()
        ReactGA.pageview(props.location.pathname + props.location.search);
    }, [props])

    function getData() {
        getItem(id)
        .then((res) => {
            document.title = res.data.data.name + ' | ' + res.data.data.author_name + ' | Tričkovník'
            setProduct(res.data.data)
            setLoading(false)
            setHit(res.data.data.id_product)
            .catch((err) => console.log(err))
        })
        .catch((err) => {
            console.log(err)
            setLoading(false)
        })
    }

    return (
        <>
            <Container>
                <ProductInfoRow>
                    <Col sm={3}>
                        <ProductImage src={product.image} />
                    </Col>
                    <ProductMiddleContainer sm={7}>
                        <ProductInfoContainer>
                            <ProductName>{product.name}</ProductName>
                            <AuthorName>{product.author_name}</AuthorName>
                            <RatingStarsForm id_product={product.id_product} />
                            <ProductDescriptionContainer>
                                <ProductDescription>{product.description}</ProductDescription>
                                <ShowMoreLink href={`/koupit/${product.feed_id}`} target='_blank'>Více informací</ShowMoreLink>
                            </ProductDescriptionContainer>
                        </ProductInfoContainer>
                        {/*
                        <InfoLabel style={{marginTop: 40, marginBottom: 20}}>Porovnání cen</InfoLabel>
                        <PricesList ean={product.ean} />
                        */}

                    </ProductMiddleContainer>

                    <Col>
                        <Row>
                            <ProductActionsContainer sm={12}>
                                <ProductPrice>{product.price_vat}&nbsp;Kč</ProductPrice>
                                <BuyButton href={`/koupit/${product.feed_id}`} target='_blank'>Koupit</BuyButton>
                            </ProductActionsContainer>
                            {/*
                            <ProductSideInfoContainer sm={12}>
                                <InfoLabel>Žánr</InfoLabel>
                                <InfoValue>{product.genre_name}</InfoValue>
                                <InfoLabel>Author</InfoLabel>
                                <InfoValue>{product.author_name}</InfoValue>
                                <InfoLabel>Nakladatelství</InfoLabel>
                                <InfoValue>{product.publisher_name}</InfoValue>
                            </ProductSideInfoContainer>
                            */}
                        </Row>
                    </Col>

                </ProductInfoRow>
            </Container>
            <PageSection bg='#fff'>
                <Container>
                    <SectionHeading>Další v této kategorii</SectionHeading>
                    <MostViewedContainer type='category-id' id={product.id_category} />
                </Container>
            </PageSection>
        </>
    )
}

export default ProductView