import React, { useState, useEffect } from 'react'
import ProductResultsModule from '../../containers/search-modules/modules/ProductResultsModule'
import { Col, Container, Row } from 'reactstrap'
import { PageSection, PageTitle, SearchQuery, SectionHeading } from '../../../styles/sections'
import queryString from 'query-string'
import CategoriesResultsModule from '../../containers/search-modules/modules/CategoriesResultsModule'
import { theme } from '../../../styles/vars'
import ReactGA from 'react-ga'

function SearchResultsView(props) {

    const parsed = queryString.parse(props.location.search);

    useEffect(() => {
        ReactGA.pageview(props.location.pathname + props.location.search);
        window.scrollTo(0, 0);
    }, [props])

    return (
        <>
            <Container>
                <PageSection>
                    <Row>
                        <Col>
                            <PageTitle>
                                Výsledky vyhledávání pro dotaz: <SearchQuery>{parsed.q}</SearchQuery>
                            </PageTitle>
                        </Col>
                    </Row>
                </PageSection>
            </Container>
            <PageSection bg={theme.bg_secondary}>
                <Container>
                    <SectionHeading>Kategorie</SectionHeading>
                    <CategoriesResultsModule props={props} />
                </Container>
            </PageSection>
            <PageSection>
                <Container>
                    <SectionHeading>Produkty</SectionHeading>
                    <ProductResultsModule props={props} />
                </Container>
            </PageSection>
        </>
    )   
}

export default SearchResultsView