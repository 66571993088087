import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { shadow1, theme } from './vars'

export const MobileNavWrapper = styled.div`
    width: 100%;
    position: relative;
    z-index: 3;
    &.scrolled {
        position: fixed; 
        top: 0; 
        left: 0; 
        right: 0;
        box-shadow: ${shadow1};
        z-index: 9999;
    }
    transition: all .3s ease-out;
    background: #fff;
    padding: 1.5rem;

    .ui.basic.button, .ui.basic.buttons .button {
        border-color: transparent;
        box-shadow: none;
        color: #212529 !important;
        font-size: 16px;
        padding: 8px;
    }
`

export const Logo = styled.img`
    max-width: 100%;
`

export const NavModalWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    background: #fff;
    z-index: 9999;
    opacity: 0;
    animation: fadeIn .3s ease-out forwards;
    padding: 30px;

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
`

export const NavModalCloseWrapper = styled.div`
    position: fixed;
    top: 1.5rem;
    right: 1.5rem;
`

export const NavModalContent = styled.div`
    margin-top: 60px;

    .ui.form input[type=text] {
        padding: 15px;
        border: 1px solid #f6f6f6;
        transition: all .3s ease-out;
        margin-bottom: 10px;
    }
`

export const MobileNavLink = styled(NavLink)`
    display: block;
    padding-top: 15px;
    padding-bottom: 15px;
    font-weight: 700;
    color: ${theme.title};
`