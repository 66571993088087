import axios from 'axios';
import { api_url, token } from '../config/api';

export function searchProducts(query) {
    return axios({
        method: 'get',
        url: api_url + 'public/search/search-products?' + query,
        headers: { Token: token },
        withCredentials: true
    })
}

export function searchCategories(query) {
    return axios({
        method: 'get',
        url: api_url + 'public/search/search-categories?' + query,
        headers: { Token: token },
        withCredentials: true
    })
}

export function getLastSearches() {
    return axios({
        method: 'get',
        url: api_url + 'public/search/get-last-searches',
        headers: { Token: token },
        withCredentials: true
    })
}