import { Col, Row } from 'reactstrap'
import styled from 'styled-components'
import { rad2, shadow1, theme } from './vars'

export const ProductImage = styled.img`
    width: 100%;
    box-shadow: ${shadow1};
    border-radius: ${rad2};

    @media (max-width: 576px) {
        margin-bottom: 30px;
    }
`

export const ProductMiddleContainer = styled(Col)`
    @media (min-width: 768px) {
        padding-left: 50px;
        padding-right: 50px;
        padding-top: 50px;
    }
`

export const ProductInfoContainer = styled.div``

export const ProductName = styled.h1`
    font-weight: 700;
`

export const AuthorName = styled.h2`
    font-weight: 700;
    color: ${theme.primary};
`

export const ProductDescription = styled.p``

export const ProductInfoRow = styled(Row)`
    margin-top: 40px;
`

export const ProductDescriptionContainer = styled.div`
    margin-top: 40px;
`

export const ShowMoreLink = styled.a``

export const ProductActionsContainer = styled(Col)`
    background: ${theme.bg_secondary};
    border-radius: ${rad2};
    padding-top: 30px;
    padding-bottom: 30px;
    margin-bottom: 40px;
`

export const ProductPrice = styled.p`
    font-weight: 700;
    text-align: center;
    font-size: 3rem;
`

export const BuyButton = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    border: 1px solid ${theme.primary};
    background-color: ${theme.primary};
    color: #fff;
    border-radius: ${rad2};
    font-weight: 700;
    text-align: center;

    :hover {
        color: #fff;
        background: ${theme.title};
        border: 1px solid ${theme.title};
        cursor: pointer;
    }
`

export const ProductSideInfoContainer = styled(Col)`
    padding-bottom: 30px;
    margin-bottom: 40px;
`

export const InfoLabel = styled.p`
    font-weight: 700;
    margin-bottom: 0;
`

export const InfoValue = styled.p``

export const GenreName = styled.span`
    text-transform: lowercase;
`