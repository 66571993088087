import React, { useState } from 'react'
import { Button } from 'semantic-ui-react'
import { MobileFiltersButton } from '../../../styles/filters'
import MobileFiltersModal from '../../modals/MobileFiltersModal'
import FiltersModule from '../category-modules/FiltersModule'

function Filters({ isMobile, alias, props }) {

    const [filtersModal, setFiltersModal] = useState(false)

    const toggleFiltersModal = () => setFiltersModal(!filtersModal)

    if(isMobile) {
        return (
            <>
            <MobileFiltersButton fluid onClick={toggleFiltersModal}>Filtrovat</MobileFiltersButton>
            <MobileFiltersModal alias={alias} props={props} hide={toggleFiltersModal} visible={filtersModal} />
            </>
        )
    } else {
        return (
            <>
            {/*
            <FiltersModule type='genre' searchable={true} limit={5} name='zanr' props={props} alias={alias} /> */}
            <FiltersModule type='price' searchable={false} limit={3} name='cena' props={props} alias={alias} />
            {/*<FiltersModule type='publisher' searchable={true} limit={5} name='nakladatelstvi' props={props} alias={alias} />*/}
            </>
        )
    }
}

export default Filters