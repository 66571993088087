import axios from 'axios';
import { api_url, token } from '../config/api';

export function getNavigation() {
    return axios({
        method: 'get',
        url: api_url + 'public/navigation/get-navigation',
        headers: {Token: token},
        withCredentials: true
    })
}

export function getFooterNavigation() {
    return axios({
        method: 'get',
        url: api_url + 'public/navigation/get-footer-navigation',
        headers: {Token: token},
        withCredentials: true
    })
}