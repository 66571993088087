import React from 'react'
import { Col, Row } from 'reactstrap'
import { Placeholder } from 'semantic-ui-react'
import { CategoryLink, CategoryLinkContainer, LinksContainer, Price, ProductCardContainer, ProductCardContent, ProductImage, ProductImageContainer, ProductName, ProductNameContainer, StoreLink } from '../../../styles/elems'

function LoadingCategoryProducts() {

    return (
        <Row>
            <Col sm={3} xs={6}>
                <ProductCardContainer>
                    <ProductImageContainer>
                        <Placeholder style={{width: 100 + '%', height: 100 + '%'}}>
                            <Placeholder.Image square />
                        </Placeholder>
                    </ProductImageContainer>
                    <ProductCardContent>
                        <ProductNameContainer>
                            <Placeholder>
                                <Placeholder.Line length='medium' />
                            </Placeholder>
                        </ProductNameContainer>
                        <CategoryLinkContainer>
                            <Placeholder>
                                <Placeholder.Line />
                            </Placeholder>
                        </CategoryLinkContainer>
                        <CategoryLinkContainer>
                            <Placeholder>
                                <Placeholder.Line />
                            </Placeholder>
                        </CategoryLinkContainer>
                        <LinksContainer>
                            <Row className='d-flex align-items-center'>
                                <Col sm={6}>
                                    <Placeholder>
                                        <Placeholder.Line length='medium' />
                                    </Placeholder>
                                </Col>
                                <Col sm={6}>
                                    <Placeholder>
                                        <Placeholder.Line length='medium' />
                                    </Placeholder>
                                </Col>
                            </Row>
                        </LinksContainer>
                    </ProductCardContent>
                </ProductCardContainer>
            </Col>
            <Col sm={3} xs={6}>
                <ProductCardContainer>
                    <ProductImageContainer>
                        <Placeholder style={{width: 100 + '%', height: 100 + '%'}}>
                            <Placeholder.Image square />
                        </Placeholder>
                    </ProductImageContainer>
                    <ProductCardContent>
                        <ProductNameContainer>
                            <Placeholder>
                                <Placeholder.Line length='medium' />
                            </Placeholder>
                        </ProductNameContainer>
                        <CategoryLinkContainer>
                            <Placeholder>
                                <Placeholder.Line />
                            </Placeholder>
                        </CategoryLinkContainer>
                        <CategoryLinkContainer>
                            <Placeholder>
                                <Placeholder.Line />
                            </Placeholder>
                        </CategoryLinkContainer>
                        <LinksContainer>
                            <Row className='d-flex align-items-center'>
                                <Col sm={6}>
                                    <Placeholder>
                                        <Placeholder.Line length='medium' />
                                    </Placeholder>
                                </Col>
                                <Col sm={6}>
                                    <Placeholder>
                                        <Placeholder.Line length='medium' />
                                    </Placeholder>
                                </Col>
                            </Row>
                        </LinksContainer>
                    </ProductCardContent>
                </ProductCardContainer>
            </Col>
            <Col sm={3} xs={6}>
                <ProductCardContainer>
                    <ProductImageContainer>
                        <Placeholder style={{width: 100 + '%', height: 100 + '%'}}>
                            <Placeholder.Image square />
                        </Placeholder>
                    </ProductImageContainer>
                    <ProductCardContent>
                        <ProductNameContainer>
                            <Placeholder>
                                <Placeholder.Line length='medium' />
                            </Placeholder>
                        </ProductNameContainer>
                        <CategoryLinkContainer>
                            <Placeholder>
                                <Placeholder.Line />
                            </Placeholder>
                        </CategoryLinkContainer>
                        <CategoryLinkContainer>
                            <Placeholder>
                                <Placeholder.Line />
                            </Placeholder>
                        </CategoryLinkContainer>
                        <LinksContainer>
                            <Row className='d-flex align-items-center'>
                                <Col sm={6}>
                                    <Placeholder>
                                        <Placeholder.Line length='medium' />
                                    </Placeholder>
                                </Col>
                                <Col sm={6}>
                                    <Placeholder>
                                        <Placeholder.Line length='medium' />
                                    </Placeholder>
                                </Col>
                            </Row>
                        </LinksContainer>
                    </ProductCardContent>
                </ProductCardContainer>
            </Col>
            <Col sm={3} xs={6}>
                <ProductCardContainer>
                    <ProductImageContainer>
                        <Placeholder style={{width: 100 + '%', height: 100 + '%'}}>
                            <Placeholder.Image square />
                        </Placeholder>
                    </ProductImageContainer>
                    <ProductCardContent>
                        <ProductNameContainer>
                            <Placeholder>
                                <Placeholder.Line length='medium' />
                            </Placeholder>
                        </ProductNameContainer>
                        <CategoryLinkContainer>
                            <Placeholder>
                                <Placeholder.Line />
                            </Placeholder>
                        </CategoryLinkContainer>
                        <CategoryLinkContainer>
                            <Placeholder>
                                <Placeholder.Line />
                            </Placeholder>
                        </CategoryLinkContainer>
                        <LinksContainer>
                            <Row className='d-flex align-items-center'>
                                <Col sm={6}>
                                    <Placeholder>
                                        <Placeholder.Line length='medium' />
                                    </Placeholder>
                                </Col>
                                <Col sm={6}>
                                    <Placeholder>
                                        <Placeholder.Line length='medium' />
                                    </Placeholder>
                                </Col>
                            </Row>
                        </LinksContainer>
                    </ProductCardContent>
                </ProductCardContainer>
            </Col>
        </Row>
    )
}

export default LoadingCategoryProducts