import React from 'react'
import { MobileHeader } from '../../../styles/elems'
import SearchForm from '../../forms/SearchForm'

function MobileHomeHeader() {
    return (
        <MobileHeader>
            <SearchForm />
        </MobileHeader>
    )
}

export default MobileHomeHeader