export const logo = {
    color: require('../assets/images/logo/trickovnik-logo-c.svg')
}

export const background = {
    home_header: require('../assets/images/background/home_header_bg.svg')
}

export const illustrations = {
    pageNotFound: require('../assets/images/illustrations/page_not_found.svg')
}