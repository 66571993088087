import React from 'react'
import { BubbleLink } from '../../../../styles/elems'

function LastSearchItem({ item, bg }) {

    return (
        <BubbleLink size='small' key={item.id_search} bg={bg} to={`/vyhledavani?q=${item.query}`}>
            {item.query}
        </BubbleLink>
    )
}

export default LastSearchItem