import React, { useState, useEffect } from 'react'
import { Col, Row } from 'reactstrap'
import { Button, Checkbox, Form, Radio } from 'semantic-ui-react'
import queryString from 'query-string'

function FilterRadioList({ list, searchable, limit = 100000, name, props }) {

    const parsed = queryString.parse(props.location.search);

    const [data, setData] = useState([])
    const [defaultData, setDefault] = useState([])
    const [limitItems, setLimit] = useState(limit)
    const [selected, setSelected] = useState([])
    const [initialLength, setInitialLength] = useState()

    useEffect(() => {
        setInitialLength(list.length)
        setData(list.slice(0, limitItems))
        setDefault(list)

        if(parsed[name]) {
            var queryItems = parsed[name].split(',')
            setSelected(queryItems)
        }
    }, [list])

    function search(e, target) {

        if(e.target.value !== '') {
            
            var oldData = defaultData
            var newData = oldData.filter((item) => item.text.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1)

            setData(newData)

        } else {
            setData(defaultData.slice(0, limitItems))
        }
        
    }

    function showAll() {
        setData(defaultData)
        setLimit(1000000)
        console.log(selected, selected.includes("3"))
    }

    function hide() {
        setLimit(limit)
        setData(defaultData.slice(0, limit))
    }

    function check(e, { value }) {

        const params = {
            ...parsed,
            page: null,
            [name]: value
        }

        const url = queryString.stringify(params, {
            skipNull: true
        });

        props.history.push(`${props.location.pathname}?${url}`)
        window.scrollTo(0, 0)

    }

    return (
        <>
            {searchable &&
            <Row className='mb-3'>
                <Col>
                    <Form>
                        <Form.Input 
                            type="text"
                            name="filter_search"
                            placeholder="Hledat..."
                            onChange={search}
                        />
                    </Form>
                </Col>
            </Row>
            }
            <Row>
                <Col>
                    <Form>
                        <Form.Field>
                            {
                                data.map((item) => {
                                    return (
                                        <div key={item.key}>
                                        <Radio
                                            label={item.text}
                                            value={item.id}
                                            onChange={check}
                                            checked={parsed[name] === item.id}
                                        />
                                        </div>
                                    )
                                })
                            }
                        </Form.Field>
                    </Form>
                </Col>
            </Row>
            {list.length > limitItems &&
                <Row className='mt-3'>
                    <Col>
                        <Button fluid onClick={() => showAll()}>Ukázat vše</Button>
                    </Col>
                </Row>
            }
            {list.length <= limitItems && list.length > limit &&
                <Row className='mt-3'>
                    <Col>
                        <Button fluid onClick={() => hide()}>Schovat</Button>
                    </Col>
                </Row>
            }
        </>
    )
}

export default FilterRadioList