import { Button } from 'semantic-ui-react'
import styled from 'styled-components'
import { defaultBorder, theme } from './vars'

export const FilterWrapper = styled.div`
    width: 100%;
    margin-bottom: 40px;
`

export const FilterHeader = styled.div`
    width: 100%;
    margin-bottom: 20px;
    border-bottom: ${defaultBorder};
    padding-bottom: 20px;
    position: relative;
`

export const FilterHeadline = styled.h2`
    font-weight: 700;
    font-size: 18px;
`

export const FilterContent = styled.div`

`

export const MobileFiltersButton = styled(Button)`
    margin-top: -30px !important;
    margin-bottom: 30px !important;
    background-color: ${theme.primary} !important;
    color: #fff !important;
`