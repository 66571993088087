import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { Button, Divider, Icon } from 'semantic-ui-react'
import { getNavigation } from '../../api/navigation'
import { MobileNavLink, NavModalCloseWrapper, NavModalContent, NavModalWrapper } from '../../styles/mobileNavigation'
import LastSearchesModule from '../containers/search-modules/modules/LastSearchesModule'
import SearchForm from '../forms/SearchForm'

function MobileMenuModal({ hide }) {

    const [navigation, setNavigation] = useState([])
    const [dropdown, setDropdown] = useState([])

    useEffect(() => {

        getNavigation()
        .then((res) => {
            setNavigation(res.data.data.nav)
            setDropdown(res.data.data.dropdown)
        })
    }, [])

    function mainNav() {
        return navigation.map((item) => {
            return (
                <MobileNavLink to={`/knihy/kategorie/${item.alias}`}>
                    {item.name}
                </MobileNavLink>
            )
        })
    }

    function secondaryNav() {
        return dropdown.map((item) => {
            return (
                <MobileNavLink to={`/knihy/kategorie/${item.alias}`}>
                    {item.name}
                </MobileNavLink>
            )
        })
    }

    return (
        <NavModalWrapper>
            <NavModalCloseWrapper>
                <Button icon onClick={() => hide()}><Icon name='close' /></Button>
            </NavModalCloseWrapper>
            <NavModalContent>
                {mainNav()}
                <Divider />
                {secondaryNav()}
            </NavModalContent>
        </NavModalWrapper>
    )
}

export default MobileMenuModal