import React from 'react'
import { Col, Row } from 'reactstrap'
import ProductCard from '../product-modules/elems/ProductCard'

function CategoryProducts({ items }) {

    return (
        <Row>
            {items.map((item) => {
                return (
                    <Col sm={3} xs={6} key={item.id_product}>
                        <ProductCard item={item}></ProductCard>
                    </Col>
                )
            })}
        </Row>
    )
}

export default CategoryProducts