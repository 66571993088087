import styled from 'styled-components'

export const LoaderContainer = styled.div`
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .ui.text.loader {
        margin-top: -3.07142857rem;
    }
`