import React, { useState, useEffect } from 'react'
import { Col, Row } from 'reactstrap'
import { Button, Icon } from 'semantic-ui-react'
import { getFilterData } from '../../../api/filters'
import { FilterContent, FilterHeader, FilterHeadline, FilterWrapper } from '../../../styles/filters'
import FilterCheckboxList from './elems/FilterCheckboxList'
import FilterRadioList from './elems/FilterRadioList'
import queryString from 'query-string'

function FiltersModule({ alias, type, searchable, limit, name, props }) {

    const parsed = queryString.parse(props.location.search);
    const [filterData, setFilterData] = useState([])
    const [hasSome, setHasSome] = useState(false)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getData()
    }, [type, alias])

    function getData() {
        getFilterData(alias, type)
        .then((res) => {
            if(res.data.type === 'success') {
                setFilterData(res.data.data)
                setHasSome(true)
                setLoading(false)
            } else {
                setHasSome(false)
                setLoading(false)
            }
        })
        .catch((err) => {
            console.log(err)
            setHasSome(false)
        })
    }

    function headline(type) {
        switch(type) {
            case 'publisher':
                return 'Nakladatelství'

            case 'genre':
                return 'Žánr'

            case 'price':
                return 'Cenová hladina'

            default:
                return ''
        }
    }

    function removeFilter() {
        const params = {
            ...parsed,
            page: null,
            [name]: null
        }

        const url = queryString.stringify(params, {
            skipNull: true
        });

        props.history.push(`${props.location.pathname}?${url}`)
        window.scrollTo(0, 0)
    }

    function getForm(type) {
        if(!loading && hasSome) {
            switch(type) {
                case 'publisher':
                    return <FilterCheckboxList list={filterData} searchable={searchable} limit={limit} name={name} props={props} />

                case 'genre':
                    return <FilterCheckboxList list={filterData} searchable={searchable} limit={limit} name={name} props={props} />

                case 'price':
                    return <FilterRadioList list={filterData} searchable={searchable} limit={limit} name={name} props={props} />
    
                default:
                    return ''
            }
        }
    }

    return (
        <FilterWrapper>
            <FilterHeader>
                <Row className='align-items-center'>
                    <Col xs={9}>
                        <FilterHeadline>{headline(type)}</FilterHeadline>
                    </Col>
                    <Col xs={3} className='text-right'>
                        <Button icon onClick={removeFilter}><Icon name='times' /></Button>
                    </Col>
                </Row>
            </FilterHeader>
            <FilterContent>
                {getForm(type)}
            </FilterContent>
        </FilterWrapper>
    )
}

export default FiltersModule