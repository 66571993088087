export const shadow1 = '0 0 1px 0 rgba(8, 11, 14, 0.06), 0 6px 6px -1px rgba(8, 11, 14, 0.1)';

export const rad1 = '12px';
export const rad2 = '6px';

export const bodyBg = '#fff';
export const defaultBorder = '1px solid #ececec';

export const theme = {
    primary: '#106BFF',
    title: '#151515',
    bg_secondary: '#f8f9fe'
}