import React, { useEffect, useState } from 'react'
import { Redirect, useHistory, useLocation } from 'react-router'
import { Col, Row } from 'reactstrap'
import { Button, Form } from 'semantic-ui-react'
import { SearchButton } from '../../styles/elems'
import queryString from 'query-string'

function SearchForm({props}) {

    const history = useHistory();
    const location = useLocation();

    const parsed = queryString.parse(location.search);
    const [data, setData] = useState({
        search: ''
    })
    const [error, setError] = useState(false)

    useEffect(() => {
        setData({...data, q: parsed.q})
    }, [])

    function onChange(e) {
        setData({...data, [e.target.name]: e.target.value})
    }

    function submit() {
        if(data.q !== '' && data.q !== undefined) {
            history.push(`/vyhledavani?q=${data.q}`)
        } else {
            setError(true)

            setTimeout(() => {
                setError(false)
            }, 4000)
        }
    }

    return (
        <Form onSubmit={submit}>
            <Row>
                <Col sm={10}>
                    <Form.Field>
                        <Form.Input 
                            name='q'
                            type='text'
                            placeholder='Hledat podle názvu knihy, autora, kategorie...'
                            icon='search'
                            iconPosition='left'
                            className='header_search_bar'
                            onChange={onChange}
                            value={data.q}
                            error={error ? {
                                content: 'Zadejte vyhledávací dotaz',
                                pointing: 'below',
                            } : false}
                        />
                    </Form.Field>
                </Col>
                <Col sm={2}>
                    <Form.Field>
                        <SearchButton fluid>Hledat</SearchButton>
                    </Form.Field>
                </Col>
            </Row>
        </Form>
    )
}

export default SearchForm