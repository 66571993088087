import React from 'react'
import { Dropdown } from 'semantic-ui-react'
import queryString from 'query-string'

function SortProducts({ props, alias }) {

    const parsed = queryString.parse(props.location.search);

    function submit(sort) {

        const params = {
            ...parsed,
            sort: [sort],
            page: 1
        }

        const url = queryString.stringify(params, {
            skipNull: true
        });

        props.history.push(`/kategorie/${alias}?${url}`)
    }

    function filterTitle(type) {
        switch(type) {
            case 'nejoblibenejsi':
                return 'Nejoblíbenější'

            case 'nejnovejsi':
                return 'Nejnovější'

            case 'nejlevnejsi':
                return 'Nejlevnější'

            case 'nejdrazsi':
                return 'Nejdražší'

            default:
                return 'Řazení produktů'
        }
    }

    return (
        <Dropdown text={filterTitle(parsed.sort)}>
            <Dropdown.Menu>
                <Dropdown.Item text='Nejoblíbenější' onClick={() => submit('nejoblibenejsi')} />
                <Dropdown.Item text='Nejnovější' onClick={() => submit('nejnovejsi')} />
                <Dropdown.Item text='Nejlevnější' onClick={() => submit('nejlevnejsi')} />
                <Dropdown.Item text='Nejdražší' onClick={() => submit('nejdrazsi')} />
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default SortProducts