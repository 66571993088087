import React from 'react'
import { Container } from 'reactstrap'
import { NavContainer } from '../../../styles/navigation'
import NavigationNavbar from './NavigationNavbar'
import NavigationSearchBar from './NavigationSearchBar'

function NavigationContainer(props) {

    return (
        <NavContainer>     
            <NavigationSearchBar props={props} />
            <NavigationNavbar />
        </NavContainer>
    )
}

export default NavigationContainer