import { NavLink } from 'react-router-dom'
import { DropdownToggle, Nav, Navbar, Row } from 'reactstrap'
import styled from 'styled-components'
import { defaultBorder, shadow1, theme, rad2 } from './vars'

export const NavContainer = styled.div`
    width: 100%;
    background: #fff;
    border-bottom: ${defaultBorder};
    position: relative;
    z-index: 1;
`

export const CustomNavbar = styled(Navbar)`
    &.scrolled {
        position: fixed; 
        top: 0; 
        left: 0; 
        right: 0;
        box-shadow: ${shadow1};
        z-index: 9999;
    }
    transition: all .3s ease-out;
    background: #fff;
`

export const SearchBarRow = styled(Row)`
    border-bottom: ${defaultBorder};
    padding-top: 20px;
    padding-bottom: 20px;
    align-items: center;

    .ui.form input[type=text] {
        padding: 20px;
        border: 1px solid #f6f6f6;
        transition: all .3s ease-out;
    }
`

export const CustomNavLink = styled(NavLink)`
    display: block;
    padding: 1rem;
    border-radius: ${rad2};
    background: #fff;
    font-weight: 700;
    color: ${theme.title};
    text-decoration: none !important;
    transition: all 0.4s ease-in-out;
    font-size: 1rem;

    :hover {
        color: ${theme.primary};
    }

    &.active {
        color: ${theme.primary};
    }
`

export const DropdownTrigger = styled(DropdownToggle)`
    display: block;
    padding: 1rem;
    border-radius: ${rad2};
    background: #fff;
    font-weight: 700;
    color: ${theme.title};
    text-decoration: none !important;
    transition: all 0.4s ease-in-out;
    font-size: 1rem;

    :hover {
        color: ${theme.primary};
    }

    &.active {
        color: ${theme.primary};
    }
`

export const CustomNav = styled(Nav)`
    @media (min-width: 768px) {
        justify-content: space-between;
        width: 100%;
    }
`

export const Logo = styled.img`
    height: 40px;
`