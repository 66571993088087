import React, { useState, useEffect } from 'react'
import { Col, Container, Row } from 'reactstrap'
import { Button, Pagination } from 'semantic-ui-react'
import { getProductsFromCategory } from '../../../api/products'
import { jsonToFormData } from '../../../tools'
import CategoryProducts from '../../containers/Category/CategoryProducts'
import queryString from 'query-string'
import LoadingCategoryProducts from '../../containers/Category/LoadingCategoryProducts'
import { PageSection } from '../../../styles/sections'
import MetaTags from 'react-meta-tags'
import { logo } from '../../../config/images'
import { theme } from '../../../styles/vars'
import { PageTitle } from '../../../styles/global'
import SortProducts from '../../containers/Category/SortProducts'
import FiltersModule from '../../containers/category-modules/FiltersModule'
import { isMobile, isMobileOnly } from 'react-device-detect'
import MobileFiltersModal from '../../modals/MobileFiltersModal'
import Filters from '../../containers/Category/Filters'
import ReactGA from 'react-ga'

function CategoryView(props) {

    const parsed = queryString.parse(props.location.search);
    const { alias } = props.match.params

    const [products, setProducts] = useState([])
    const [loadingProducts, setLoadingProducts] = useState(true)
    const [hasProducts, setHasProducts] = useState(false)
    const [pages, setPages] = useState(0)
    const [category, setCategory] = useState('Načítám...')
    const [filtersModal, setFiltersModal] = useState(false)

    useEffect(() => {
        getProducts()
        ReactGA.pageview(props.location.pathname + props.location.search);
        window.scrollTo(0, 0);
    }, [props])

    function getProducts() {

        setLoadingProducts(true)

        const query = queryString.stringify(parsed, {
            skipNull: true
        });
        const prep = {
            alias: alias
        }

        const req = jsonToFormData(prep)

        getProductsFromCategory(req, query)
        .then((res) => {
            setProducts(res.data.data.items)
            setPages(res.data.data.pages)
            setCategory(res.data.data.catname)
            setLoadingProducts(false)

            document.title = res.data.data.catname + ' | Tričkovník'

        })
    }

    const handlePaginationChange = (e, { activePage }) => {
        const params = {
            ...parsed,
            page: activePage
        }

        const url = queryString.stringify(params, {
            skipNull: true
        });

        props.history.push(`${process.env.PUBLIC_URL}/kategorie/${alias}?${url}`)
    }

    return (
        <>
        <MetaTags>
            <title>{!loadingProducts ? category + ' | Tričkovník' : 'Kategorie' + ' | Tričkovník'}</title>
            <meta name="description" content="Tričkovník" />
            <meta property="og:title" content={!loadingProducts ? category + ' | Tričkovník' : 'Kategorie' + ' | Tričkovník'} />
            <meta property="og:image" content={logo.color} />
        </MetaTags>
        <PageSection bg={theme.bg_secondary}>
            <Container>
                <PageTitle>{category}</PageTitle>
            </Container>
        </PageSection>
        <Container>
            <PageSection>
                <Row>
                    <Col sm={3}>
                        <Filters isMobile={isMobileOnly} props={props} alias={alias} />
                    </Col>
                    <Col sm={9}>
                            <Row className='mb-5'>
                                <Col className='text-right'>
                                    <SortProducts props={props} alias={alias} />
                                </Col>
                            </Row>
                        {!loadingProducts && products.length !== 0 ?
                            <>
                            <CategoryProducts items={products} />
                            </>
                        :
                            <>
                            <LoadingCategoryProducts />
                            <LoadingCategoryProducts />
                            <LoadingCategoryProducts />
                            <LoadingCategoryProducts />
                            </>
                        }
                        
                        <Row className='mt-3 d-flex justify-content-center'>
                            
                                <Pagination 
                                    activePage={parsed.page ? Number(parsed.page) : 1} 
                                    onPageChange={handlePaginationChange}
                                    totalPages={pages} 
                                    boundaryRange={isMobileOnly ? 0 : 1}
                                />

                        </Row>
                    </Col>
                </Row>
            </PageSection>
        </Container>
        </>
    )

}

export default CategoryView