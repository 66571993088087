import React, { useState, useEffect } from 'react'
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    NavbarText, Container
  } from 'reactstrap'
import { getNavigation } from '../../../api/navigation';
import { CustomNav, CustomNavbar, CustomNavLink, DropdownTrigger } from '../../../styles/navigation';
import { NavLink } from 'react-router-dom'

function NavigationNavbar() {
    
    const [isOpen, setIsOpen] = useState(false);
    const [navigation, setNavigation] = useState([])
    const [dropdown, setDropdown] = useState([])
    const [loadingNav, setLoadingNav] = useState(true)
    const [scrolled,setScrolled]=React.useState(false);
    const handleScroll = () => {
        const offset = window.scrollY;
        if(offset > 100 ){
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    }

    const toggle = () => setIsOpen(!isOpen);

    useEffect(() => {

        window.addEventListener('scroll', handleScroll)

        getNavigation()
        .then((res) => {
            setNavigation(res.data.data.nav)
            setDropdown(res.data.data.dropdown)
            setLoadingNav(false)
        })
    }, [])

    return (
        <CustomNavbar expand="md" className={scrolled ? 'scrolled' : 'not-scrolled'}>
            <Container>
                <NavbarToggler onClick={toggle} />
                <Collapse isOpen={isOpen} navbar>
                    <CustomNav className="mr-auto" navbar>
                        <NavItem>
                            <CustomNavLink to="/" exact>Domů</CustomNavLink>
                        </NavItem>

                        {navigation.map((item) => {

                            if(item.dropdown.length === 0) {
                                return (
                                    <NavItem key={item.alias}>
                                        <CustomNavLink to={`/kategorie/${item.alias}`}>{item.name}</CustomNavLink>
                                    </NavItem>
                                )
                            } else {
                                return (
                                    <UncontrolledDropdown nav inNavbar>
                                        <DropdownTrigger nav caret>
                                            {item.name}
                                        </DropdownTrigger>
                                        <DropdownMenu right>
                                            {item.dropdown.map((item) => {
                                                return (
                                                    <NavLink key={item.alias} to={`/kategorie/${item.alias}`}>
                                                        <DropdownItem>{item.name}</DropdownItem>
                                                    </NavLink>
                                                )
                                            })}
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                )
                            }
                        })}
                    </CustomNav>
                </Collapse>
            </Container>
        </CustomNavbar>
    )
}

export default NavigationNavbar