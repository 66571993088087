import { Link, NavLink } from 'react-router-dom'
import { Col } from 'reactstrap'
import styled from 'styled-components'
import { shadow1, theme } from './vars'

export const FooterContainer = styled.div`
    width: 100%;
    background: ${theme.primary};
    padding-top: 70px;
    padding-bottom: 70px;
`

export const FooterNav = styled.ul`
    column-count: 3;
    list-style-type: none;
    margin: 0;
    padding: 0;

    @media (max-width: 567px) {
        column-count: 1;
    }
`

export const FooterNavItem = styled.li`
    margin-bottom: 10px;
`

export const FooterNavLink = styled(Link)`
    color: #fff;
    font-weight: 700;
    font-size: 1.2rem;
`

export const FooterSectionHeadline = styled.h3`
    color: #fff;
    font-size: 1.5rem;
    margin-bottom: 20px;
    font-weight: 700;
`

export const FooterText = styled.p`
    color: rgba(255,255,255,.7);
`

export const FooterBox = styled(Col)`
    @media (max-width: 567px) {
        margin-bottom: 50px;
    }
`