import axios from 'axios';
import { api_url, token } from '../config/api';

export function getMostViewed() {
    return axios({
        method: 'get',
        url: api_url + 'public/products/get-most-viewed',
        headers: { Token: token },
        withCredentials: true
    })
}

export function getNewest() {
    return axios({
        method: 'get',
        url: api_url + 'public/products/get-newest',
        headers: { Token: token },
        withCredentials: true
    })
}

export function getItem(id) {
    return axios({
        method: 'get',
        url: api_url + 'public/products/get-item/' + id,
        headers: { Token: token },
        withCredentials: true
    })
}

export function getCategoryById(id) {
    return axios({
        method: 'get',
        url: api_url + 'public/products/get-category-by-id/' + id,
        headers: { Token: token },
        withCredentials: true
    })
}

export function getGenreItems(id) {
    return axios({
        method: 'get',
        url: api_url + 'public/products/get-genre-items/' + id,
        headers: { Token: token },
        withCredentials: true
    })
}

export function setHit(id) {
    return axios({
        method: 'get',
        url: api_url + 'public/products/set-hit/' + id,
        headers: { Token: token },
        withCredentials: true
    })
}

export function getProductsFromCategory(data, query) {
    return axios({
        method: 'post',
        url: api_url + 'public/products/get-products-from-category?' + query,
        data: data,
        headers: {Token: token},
        withCredentials: true
    })
}

export function getPricesList(ean) {
    return axios({
        method: 'get',
        url: api_url + 'public/products/get-prices/' + ean,
        headers: { Token: token },
        withCredentials: true
    })
}

export function getLink(id) {
    return axios({
        method: 'get',
        url: api_url + 'public/products/get-link/' + id,
        headers: { Token: token },
        withCredentials: true
    })
}