import React from 'react'
import { isMobile } from 'react-device-detect'
import { Link } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import { AuthorLink, CategoryLink, CategoryLinkContainer, LinksContainer, Price, PriceFrom, ProductCardContainer, ProductCardContent, ProductImage, ProductImageContainer, ProductName, ProductNameContainer, StoreLink } from '../../../../styles/elems'
import { shortText } from '../../../../tools'

function ProductCard({item}) {

    return (
        <ProductCardContainer>
            <Link to={`/produkt/${item.alias}/${item.feed_id}`}>
                <ProductImageContainer>
                    <ProductImage src={item.image} />
                </ProductImageContainer>
            </Link>
            <ProductCardContent>
                <ProductNameContainer>
                    <ProductName><Link to={`/produkt/${item.alias}/${item.feed_id}`}>{shortText(item.name, 40)}</Link></ProductName>
                </ProductNameContainer>
                {!isMobile &&
                <>
                <CategoryLinkContainer>
                    <AuthorLink>{item.author_name}</AuthorLink>
                </CategoryLinkContainer>
                <CategoryLinkContainer>
                    <CategoryLink to={`/kategorie/${item.cat_alias}`}>{item.cat_name}</CategoryLink>
                </CategoryLinkContainer>
                </>
                }
                <LinksContainer>
                    <Row className='d-flex align-items-center'>
                        <Col sm={6}>
                            <Price><PriceFrom>Od</PriceFrom>&nbsp;{item.price_vat},-&nbsp;Kč</Price>
                        </Col>
                        <Col sm={6}>
                            <StoreLink href={`/koupit/${item.feed_id}`} target='_blank'>Koupit</StoreLink>
                        </Col>
                    </Row>
                </LinksContainer>
                
            </ProductCardContent>
        </ProductCardContainer>
    )

}

export default ProductCard