import React, { useState, useEffect } from 'react'
import { Col, Row } from 'reactstrap'
import { Button, Checkbox, Form } from 'semantic-ui-react'
import queryString from 'query-string'

function FilterCheckboxList({ list, searchable, limit = 100000, name, props }) {

    const parsed = queryString.parse(props.location.search);

    const [data, setData] = useState([])
    const [defaultData, setDefault] = useState([])
    const [limitItems, setLimit] = useState(limit)
    const [selected, setSelected] = useState([])
    const [initialLength, setInitialLength] = useState()

    useEffect(() => {
        setInitialLength(list.length)
        setData(list.slice(0, limitItems))
        setDefault(list)

        if(parsed[name]) {
            var queryItems = parsed[name].split(',')
            setSelected(queryItems)
        } else {
            setSelected([])
        }
    }, [list, props.location.search])

    function search(e, target) {

        if(e.target.value !== '') {
            
            var oldData = defaultData
            var newData = oldData.filter((item) => item.text.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1)

            setData(newData)

        } else {
            setData(defaultData.slice(0, limitItems))
        }
        
    }

    function showAll() {
        setData(defaultData)
        setLimit(1000000)
    }

    function hide() {
        setLimit(limit)
        setData(defaultData.slice(0, limit))
    }

    function check(e, data, id) {

        var selectedItems = selected

        if(data.checked) {
            selectedItems.push(id.toString())
        } else {
            const index = selectedItems.indexOf(id.toString());
            if (index > -1) {
                selectedItems.splice(index, 1);
            }
        }

        setSelected(selected)

        const params = {
            ...parsed,
            page: null,
            [name]: selected.length !== 0 ? selected.join(',') : null
        }

        const url = queryString.stringify(params, {
            skipNull: true
        });

        var pretty = url.replace('%2C',',');

        props.history.push(`${props.location.pathname}?${pretty}`)
        window.scrollTo(0, 0)

    }

    return (
        <>
            {searchable &&
            <Row className='mb-3'>
                <Col>
                    <Form>
                        <Form.Input 
                            type="text"
                            name="filter_search"
                            placeholder="Hledat..."
                            onChange={search}
                        />
                    </Form>
                </Col>
            </Row>
            }
            {
                data.map((item) => {
                    return (
                        <Row className='align-items-center mb-1' key={item.key}>
                            <Col xs={1}>
                                <Checkbox checked={selected.includes(item.id.toString())} onChange={(e, data) => check(e, data, item.id)} />
                            </Col>
                            <Col xs={10}>
                                <span>{item.text}</span>
                            </Col>
                        </Row>
                    )
                })
            }
            {list.length > limitItems &&
                <Row className='mt-3'>
                    <Col>
                        <Button fluid onClick={() => showAll()}>Ukázat vše</Button>
                    </Col>
                </Row>
            }
            {list.length <= limitItems && list.length > limit &&
                <Row className='mt-3'>
                    <Col>
                        <Button fluid onClick={() => hide()}>Schovat</Button>
                    </Col>
                </Row>
            }
        </>
    )
}

export default FilterCheckboxList