import React from 'react'
import { Col, Row } from 'reactstrap'
import { illustrations } from '../../../config/images'
import { BubbleLink, MainIllustration } from '../../../styles/elems'
import { LoaderContainer } from '../../../styles/prettyLinks'
import { PageTitle } from '../../../styles/sections'

function PageNotFound() {

    return (
        <LoaderContainer style={{paddingTop: 70, paddingBottom: 70}}>
            <Row>
                <Col>
                    <MainIllustration src={illustrations.pageNotFound} />
                    <PageTitle>Požadovaná stránka nebyla nalezena</PageTitle>
                    <BubbleLink to='/' bg='light' style={{textAlign: "center", display: 'block', maxWidth: 300, marginTop: 40, marginLeft: 'auto', marginRight: 'auto' }}>Zpět na úvodní stranu</BubbleLink>
                </Col>
            </Row>
        </LoaderContainer>
    )
}

export default PageNotFound