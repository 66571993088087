import React, { useState, useEffect } from 'react'
import { getLastSearches } from '../../../../api/search'
import { LastSearchesRow } from '../../../../styles/sections';
import LastSearchItem from '../elems/LastSearchItem';

function LastSearchesModule({ bg }) {

    const [items, setItems] = useState([]);

    useEffect(() => {
        getData()
    }, [])

    function getData() {
        getLastSearches()
        .then((res) => {
            if(res.data.type === 'success') {
                setItems(res.data.data)
            }
        })
        .catch((err) => {
            console.log(err)
        })
    }

    return (
        <LastSearchesRow>
            {items.map((item) => {
                return (
                    <LastSearchItem item={item} bg={bg} key={item.id_search} />
                )
            })}
        </LastSearchesRow>
    )

}

export default LastSearchesModule