import React, { useEffect } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { Container } from 'reactstrap'
import { PageSection, SectionHeading } from '../../../styles/sections'
import { theme } from '../../../styles/vars'
import HomeHeader from '../../containers/Home/HomeHeader'
import MobileHomeHeader from '../../containers/Home/MobileHomeHeader'
import MostViewedContainer from '../../containers/product-modules/MostViewedContainer'
import ReactGA from 'react-ga'

function HomeView(props) {

    useEffect(() => {
        ReactGA.pageview(props.location.pathname + props.location.search);
        window.scrollTo(0, 0);
    }, [props])

    return (
        <>
            {/*isMobileOnly ? 
            <MobileHomeHeader />
            :
            <HomeHeader />
            */}
            
            <PageSection>
                <Container>
                    <SectionHeading>Nejoblíbenější produkty</SectionHeading>
                    <MostViewedContainer type='most-viewed' autoplay={true} />
                </Container>
            </PageSection>
            <PageSection bg={theme.bg_secondary}>
                <Container>
                    <SectionHeading>Nejnovější produkty</SectionHeading>
                    <MostViewedContainer type='new' autoplay={true} />
                </Container>
            </PageSection>
        </>
    )

}

export default HomeView