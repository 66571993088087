import React from 'react'
import { Link } from 'react-router-dom'
import { Col, Container, Row } from 'reactstrap'
import { Form } from 'semantic-ui-react'
import { logo } from '../../../config/images'
import { Logo, SearchBarRow } from '../../../styles/navigation'
import SearchForm from '../../forms/SearchForm'

function NavigationSearchBar(props) {

    return (
        <Container>
            <SearchBarRow>
                <Col sm={4} className='d-flex'>
                    <Link to='/'>
                        <Logo src={logo.color} />
                    </Link>
                </Col>
                <Col sm={8}>
                    <SearchForm />
                </Col>
            </SearchBarRow>
        </Container>
    )
}

export default NavigationSearchBar