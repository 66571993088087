import React, {useEffect} from 'react'
import { getLink } from '../../../api/products';
import { LoaderContainer } from '../../../styles/prettyLinks';
import { Loader } from 'semantic-ui-react';

function PrettyLinksView(props) {

    const { id } = props.match.params

    useEffect(() => {
        getLink(id)
        .then((res) => {
            if(res.data.type === 'success') {
                window.location.href = res.data.link
            }
        })
    }, [])
    

    return (
        <LoaderContainer>
            <Loader active>Přesměrovávám do obchodu</Loader>
        </LoaderContainer>
    )
}

export default PrettyLinksView