import React from 'react'
import { Route, Switch, withRouter } from 'react-router'
import { Container } from 'reactstrap'
import PageNotFound from '../components/views/404/PageNotFound'
import CategoryView from '../components/views/Category/CategoryView'
import HomeView from '../components/views/Home/HomeView'
import PrettyLinksView from '../components/views/PrettyLinks/PrettyLinksView'
import ProductView from '../components/views/Product/ProductView'
import SearchResultsView from '../components/views/Search/SearchResultsView'
import { GlobalStyle } from '../styles/global'

function PublicRoutes() {

    return (
        <>
        <GlobalStyle />
        <Switch>
            <Route path='/' component={withRouter(HomeView)} exact />
            <Route path='/kategorie/:alias/:genre?' component={withRouter(CategoryView)} exact />
            <Route path='/produkt/:alias/:id' component={withRouter(ProductView)} exact />
            <Route path='/koupit/:id' component={withRouter(PrettyLinksView)} exact />
            <Route path='/vyhledavani' component={withRouter(SearchResultsView)} exact />
            <Route path='*' component={PageNotFound} />
        </Switch>
        </>
    )

}

export default PublicRoutes